
    import {useRouter} from 'vue-router'
    import {useStore} from 'vuex'
    import { resetRouter } from '@/router'
    import store from "@/store";
    export default {
        name: "Commonheader",
        setup() {
            const router = useRouter();
            const store = useStore();
            const logout=()=>{
                resetRouter();
                router.push('/login');
                store.dispatch('tokeninfo',null)
                store.dispatch('uidinfo',null)
                store.dispatch('navinfo',null)
                store.dispatch('authinfo',null)
                store.dispatch('unameinfo',null)
                window.localStorage.clear();
                window.sessionStorage.clear();
            }
            return {
                logout,
            }
        }
    }
