
    import {defineComponent, ref, onMounted,toRaw,getCurrentInstance,onUpdated} from 'vue'
    import {useRouter, useRoute} from 'vue-router'
    import CommonHeader from "@/components/common/CommonHeader.vue";
    import {getNav} from "../network/admin";
    import {Icon} from '@/util/icon'
    import {useStore} from 'vuex'
    import {
        AreaChartOutlined
    } from '@ant-design/icons-vue';
    import {isJSON} from "@/util/util";
    import { notification } from 'ant-design-vue';
    import {useNativeNotifications} from "vue3-native-notification";
    export default defineComponent({
        name: 'Admin',
        setup() {
            const nativeNotification = useNativeNotifications();
            const { proxy } = getCurrentInstance() as any;
            const internalInstance:any = getCurrentInstance();
            let selectedKeys = ref(['0']);
            const router = useRouter();
            let k:any = window.sessionStorage.getItem('key');
            const menu = (s, key) => {
                window.sessionStorage.setItem('key', key);
                var k1:any = window.sessionStorage.getItem('key');
                selectedKeys.value= [k1];
                router.push(s);
            };
            const store = useStore();
            const nav = ref<any>([]);
            const notify = function (title,body) {
              nativeNotification.show(
                  title,
                  {
                    body: body,
                  },
                  {},
              )
            }

            onMounted(() => {
                //console.log(internalInstance?.proxy.$socket);
                selectedKeys.value = [k];
                if(internalInstance?.proxy.$socket.readyState==1){
                    internalInstance?.proxy.$socket.sendObj({
                        type: 'bind',
                        creator:window.localStorage.getItem('uid'),
                    });
                }
                proxy.$socket.onmessage = (res: {
                    data: any;
                }) => {
                    var data = JSON.parse(res.data);
                    console.log(data);
                    if(data.type == 'text'&&data.mode==1){
                        notification.open({
                            message: '你有新的工单！',
                            description:`工单创建人：${data.uname}, 马帮编号：${data.sn}`,
                            duration:20,
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                        });
                      notify('你有新的工单！',`工单创建人：${data.uname}, 马帮编号：${data.sn}`);
                    }else if(data.type == 'text'&&data.mode==2){
                        notification.open({
                            message: '你有新的任务，待审核！',
                            description:`工单创建人：${data.creator}`,
                            duration:20,
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                        });
                        notify('你有新的任务，待审核！',`工单创建人：${data.creator}`);

                    }else if(data.type == 'text'&&data.mode==3){
                        notification.open({
                            message: '你有新的任务！',
                            description:`工单创建人：${data.creator}`,
                            duration:20,
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                        });
                      notify('你有新的任务！',`工单创建人：${data.creator}`);
                    }else if(data.type == 'text'&&data.mode==4){
                        notification.open({
                            message: '你有新的任务！',
                            description:'有新的工单给你',
                            duration:20,
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                        });
                      notify('你有新的任务！','有新的工单给你');
                    }else if(data.type == 'text'&&data.mode==5){
                        notification.open({
                            message: '你的任务被驳回！',
                            description:'工单被驳回',
                            duration:20,
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                        });
                      notify('你的任务被驳回！','工单被驳回');
                    }/*else if(data.type == 'message'){
                        notification.open({
                            message: '',
                            description:'对方已下线！',
                            duration:20,
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                        });
                    }*/
                }
            });
            onUpdated(()=>{
                if(internalInstance?.proxy.$socket.readyState==1){
                    internalInstance?.proxy.$socket.sendObj({
                        type: 'bind',
                        creator:window.localStorage.getItem('uid'),
                    });
                }
            })
            return {
                store,
                menu,
                nav,
                collapsed: ref<boolean>(false),
                selectedKeys,
            }
        },

        components: {
            AreaChartOutlined,
            CommonHeader,
            Icon,
        },
    })
